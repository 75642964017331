<app-header></app-header>

<app-banner-pim></app-banner-pim>

<div class="row">
  <div class="col-sm">
    <h6 class="p-2 order-instructions text-center">Efetue as suas reservas de produtos/medicamentos para levantamento na farmácia, escolhendo uma das seguintes opções:</h6>
  </div>
</div> 
<div class="row">
  <div class="col-6 text-right">
    <a (click)="book(true); false" (keyup)="book(true)" tabindex="0" href="#" class="btn btn-primary btn-md" title="Reserva com Receita Médica" role="button"><i class="fas fa-external-link-alt"></i>&nbsp;<strong>Com</strong> Receita Médica</a>
  </div>
  <div class="col-6">
    <a (click)="book(false); false" (keyup)="book(false)" tabindex="1" href="#" class="btn btn-primary btn-md" title="Reserva sem Receita Médica" role="button"><i class="fas fa-external-link-alt"></i> Sem Receita Médica</a>
  </div>
</div>
<div class="row">
  <div class="col-sm text-center terms-conditions-agreement">
    <div class="custom-control custom-checkbox">
      <input type="checkbox" class="custom-control-input" checked="checked" id="customCheck1" disabled="disabled">
      <label class="custom-control-label" for="customCheck1">Ao prosseguir com o processo de reserva, concorda com os <span (click)="onTermsConditionsClick()" tabindex="3" (keydown)="onTermsConditionsClick()" class="terms-conditions-link">termos e condições</span>.</label>
    </div>
  </div>
</div>
<div class="row" *ngIf="isTermsConditionsVisible()">
  <small class="shadow-none p-3 m-3 bg-light rounded text-justify">
    <strong>FARMACIA HUGUETTE RIBEIRO, LDA</strong><br>
    <strong>NIPC: 515488356</strong><br>
    O Utente ao encomendar afirma que tem idade igual ou superior a 18 anos de idade.<br>
    Ao fazer a sua encomenda neste website está a concordar e fica vinculado com os termos descritos no mesmo. Se não concordar com qualquer parte dos termos, então não pode aceder ao website.<br><br>

    <strong>Conteúdo</strong><br>
    O conteúdo deste website é para sua informação geral e uso. O mesmo poderá efetuar alteração sem necessidade de aviso prévio.<br><br>

    <strong>Alterações aos Termos</strong><br>
    Reservamo-nos o direito, de modificar ou substituir estes Termos a qualquer momento. Se uma revisão for substancial (a qual é determinada pelo nosso exclusivo critério) iremos tentar enviar um pré-aviso, antes da implementação de novos prazos.<br><br>

    <strong>Contacte-nos</strong><br>
    Se tiver alguma dúvida ou comentário sobre estes Termos, entre em contacto connosco pelo endereço de email: farmacia.huguette.ribeiro&#64;gmail.com, ou envie uma carta para a nossa morada: Rua Mar e Guerra, N.º 31, 8005-511 Patacão, Faro
  </small>
</div>
<hr/>
<div class="row owner-and-address">
  <div class="col-6 col-lg-3">
    <strong>DIR. TÉCNICA</strong>
    <div>Dra. Huguette Ribeiro</div>
    <div><img src="/assets/infarmed.svg" class="logo-infarmed" alt="Infarmed Logo"></div>    
  </div>
  <div class="col-6 col-lg-3">
    <strong>MORADA</strong>
    <div>R. Mar e Guerra, N.º 31</div>
    <div>8005-511 Patacão, Faro</div>
    <br>      
    <div style="padding-bottom: 5px;">
      <div class="fb-like" data-href="https://www.facebook.com/farmaciahuguetteribeiro" data-width="" data-layout="button_count" data-action="like" data-size="small" data-share="true"></div>
    </div>
    <a href="https://www.facebook.com/farmaciahuguetteribeiro" class="btn btn-sm" style="background: #4267B2; color: #ffffff;"><i class="fab fa-facebook"></i> Facebook</a>
    &nbsp;<a (click)="waze()" href="#" class="btn btn-info btn-sm"><i class="fab fa-waze"></i> WAZE</a>      
  </div>
  <div class="col-12 col-lg-6">
    <div #mapContainer id="map"></div>
  </div>
</div>

    