import { Component } from '@angular/core';
import { NgcCookieConsentService } from 'ngx-cookieconsent';

@Component({
  selector: 'app-root',
  template: `
  <div class="container">
    <router-outlet></router-outlet>
    <app-footer></app-footer>
  </div>
  `,
})
export class AppComponent {
  constructor(private ccService: NgcCookieConsentService) {
  }

}
