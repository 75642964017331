<div class="row">
  <div class="col-6">
    <img src="/assets/holding-hands-1773113.jpg" alt="Holding hands" class="w-100 h-100" />
  </div>
  <div class="col-6">
    <div class="banner-take-care mh-100">
      <div class="d-none d-md-block d-lg-block d-xl-block">
        <h3>CUIDE DE SI! CUIDE DE NÓS!</h3>
        <h5 class="d-none d-sm-block">
          Neste momento para prevenção COVID-19, faça a sua encomenda antes de
          vir à farmácia.
        </h5>
      </div>

      <div class="d-none d-sm-block d-md-none">
        <!-- Visible only on small -->
        CUIDE DE SI! CUIDE DE NÓS!
      </div>

      <div class="d-block d-sm-none">
        <!-- Visible only on xs -->
        <small>CUIDE DE SI! CUIDE DE NÓS!</small>
      </div>
    </div>
  </div>
</div>
