import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HomeComponent } from './home/home.component';
import { FooterComponent } from './footer/footer.component';
import { HeaderComponent } from './header/header.component';
import {
  NgcCookieConsentConfig,
  provideNgcCookieConsent,
} from 'ngx-cookieconsent';
import { BannerCovidComponent } from "./home/banner-covid/banner-covid.component";
import { BannerPimComponent } from "./home/banner-pim/banner-pim.component";

const cookieConfig: NgcCookieConsentConfig = {
  cookie: {
    domain: 'tinesoft.github.io',
  },
  position: "bottom-left",
  theme: 'classic',
  palette: {
    popup: {
      background: '#000000',
      text: '#ffffff',
    },
    button: {
      background: 'green',
      text: '#ffffff',
    },
  },
  type: 'info',
  content: {
    message:
      'Este site utiliza cookies próprios e de terceiros para lhe proporcionar uma melhor experiência de navegação. Para saber mais sobre cookies, quais as suas finalidades e como alterar as suas definições, consulte a nossa Política de Privacidade.',
    dismiss: 'Aceito',
    deny: null,
    link: null,
    href: null,
    policy: null,
  },
};

@NgModule({
    declarations: [AppComponent, HomeComponent, FooterComponent, HeaderComponent],
    providers: [provideNgcCookieConsent(cookieConfig)],
    bootstrap: [AppComponent],
    imports: [BrowserModule, AppRoutingModule, BannerCovidComponent, BannerPimComponent]
})
export class AppModule {}
