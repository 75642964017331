<div class="row">
    <div class="col-12 col-md-6">
        <img src="/assets/LOGO-FARMACIA.fw.png" class="img-fluid logo" alt="Logo Farmacia">
    </div>
    <div class="col-12 col-md-6 text-right contacts-container">
        <div class="contacts">
        <div>Chamada para a rede fixa nacional: <a href="tel:+351289815103">289 815 103</a>&nbsp;<i class="fas fa-phone-square-alt"></i></div>
        <div (click)="emailTo()" (keydown)="emailTo()" tabindex="0" class="emailTo">farmacia.huguette.ribeiro&#64;gmail.com <i class="fas fa-envelope"></i></div>
        <div>Seg. a Sex. das 9h00-13h30 e 14h30-19h00 <i class="fas fa-clock"></i></div>
        <div>Sábados das 9h00-13h00 <i class="fas fa-clock"></i></div>
        </div>
    </div>
</div>

<hr>