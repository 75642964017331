<div class="row banner-background">
    <div class="col-md-4 col-lg-3 d-none d-sm-none d-md-block">
        <div class="text-center mb-3"><img src="/assets/holding-hands-1773113.jpg" alt="Holding hands" class="w-100 h-100" /></div>
        <h5 class="text-center title-take-care">VENHA CONHECER O PiM!<br>CUIDAMOS DE SI!</h5>
    </div>
    <div class="col-sm-12 col-md-8 col-lg-6">
        <div class="row">
            <div class="col-12">
                <h3 class="text-center title-pim">PREPARAÇÃO INDIVUALIZADA DA MEDICAÇÃO (PiM)</h3>
            </div>
        </div>
        <div class="row">
            <div class="col-4">
                <div class="text-center mb-3"><img src="/assets/icon-health-check.png" class="w-50 h-50" alt="..."></div>
                <p class="text-center title-benefits">PERMITE A REVISÃO DA MEDICAÇÃO</p>
            </div>
            <div class="col-4">
                <div class="text-center mb-3"><img src="/assets/icon-error.png" class="w-50 h-50" alt="..."></div>
                <p class="text-center title-benefits">DIMINUI OS ERROS DE MEDICAÇÃO</p>
            </div>
            <div class="col-4">
                <div class="text-center mb-3"><img src="/assets/icon-stress.png" class="w-50 h-50" alt="..."></div>
                <p class="text-center title-benefits">REDUZ O STRESS AO DOENTE E/OU AO CUIDADOR</p>
            </div>
        </div>
        <div class="row d-block d-sm-block d-md-none">
            <div class="col-12">
                <h6 class="text-center title-pim">VENHA CONHECER O PiM! CUIDAMOS DE SI!</h6>
            </div>
        </div>
    </div>
    <div class="col-lg-3 d-none d-md-none d-lg-block">
        <div class="text-center mb-3"><img src="/assets/5tomas.jpg" class="img-thumbnail w-80 h-80" alt="..."></div>
    </div>
  </div>
  