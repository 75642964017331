/* eslint-disable @typescript-eslint/ban-types */
import { AfterViewInit, Component, ElementRef, ViewChild, signal } from '@angular/core';

declare let gtag: Function;
@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements AfterViewInit {
  title = 'FarmaciaHuguetteRibeiro';
  isTermsConditionsVisible = signal(false);
  @ViewChild('mapContainer', {static: false}) gmap: ElementRef;

  // Google Maps
  zoom = 12
  center: google.maps.LatLngLiteral
  options: google.maps.MapOptions = {
    mapTypeId: 'hybrid',
    zoomControl: false,
    scrollwheel: false,
    disableDoubleClickZoom: true,
    maxZoom: 15,
    minZoom: 8,
  }

  ngAfterViewInit(): void {
    this.mapInitializer();
  }

  book(withPrescription): void {
    gtag('event', 'Iniciar Reserva', {
      'event_category': 'Reservas',
      'event_label': withPrescription ? 'Com Receita Médica' : 'Sem Receita Médica',      
    });    

    // this.router.navigateByUrl('/reservas/com-receita-medica');
    if(withPrescription) {
      location.href = 'https://farmaciahuguetteribeiro.typeform.com/to/e4rRE9';
    } else {
      location.href = 'https://farmaciahuguetteribeiro.typeform.com/to/xOKH0t';
    }
  }

  waze(): void {
    gtag('event', 'Navegação', {
      'event_category': 'Saída',
      'event_label': 'Waze',
    });

    location.href = 'https://www.waze.com/ul?place=ChIJV6DIiF2tGg0RjOTEraYZvdg&ll=37.05178340%2C-7.95193810&navigate=yes';
  }

  onTermsConditionsClick(): void {
    this.isTermsConditionsVisible.update(value => !value);
  }

  private mapInitializer(): void {
    const coordinates = new google.maps.LatLng(37.051808, -7.9521393);
    const mapOptions: google.maps.MapOptions = {
      center: coordinates,
      zoom: 18,
    };

    new google.maps.Map(this.gmap.nativeElement, mapOptions);
  }
}
