import { Component } from '@angular/core';

@Component({
  selector: 'app-banner-covid',
  standalone: true,
  imports: [],
  templateUrl: './banner-covid.component.html',
  styleUrl: './banner-covid.component.scss'
})
export class BannerCovidComponent {

}
