<br>
<div class="row" *ngIf="isPrivacyVisible">
  <small class="shadow-none p-3 m-3 bg-light rounded text-justify">
    <strong>Política de Privacidade</strong>
    <br>As suas decisões sobre a sua informação pessoal são importantes para nós, pelo que nos comprometemos a respeitar a sua privacidade. Desta forma responsabilizamo-nos por não utilizar os seus dados para finalidades que não lhe tenham sido préviamente comunicadas. 
    <br>A nossa política de privacidade indica o tipo de dados pessoais que recolhemos, a finalidade com que os poderemos utilizar, as condições em que os conservamos e os seus direitos sobre os mesmos. Ao fornecer os seus dados pessoais e mediante leitura da nossa política de privacidade, está a dar o seu consentimento informado à sua recolha, tratamento e utilização nos moldes indicados.

    <br><br><strong>Dados Pessoais</strong>
    <br>São dados que podem permitir a sua identificação, direta ou indireta, e podem ser referentes ao público em geral ou dados pessoais de colaboradores e de fornecedores da farmácia. A informação pessoal pode incluir dados como o seu nome, contactos e preferências de utilização e consumo de produtos.

    <br><br><strong>Obtenção dos dados</strong>
    <br>A recolha dos seus dados pessoais será feita diretamente consigo através dos seguintes meios:
    <ul>
      <li>Introduziu os seus dados no nosso website.</li>
      <li>Disponibilizou-nos os seus dados para registo numa ficha de cliente que está sob a nossa responsabilidade.</li>
      <li>No processo de compra de produtos por qualquer meio: digital, telefónico ou contato direto com um dos nossos colaboradores.</li>
    </ul>

    <strong>Utilização dos dados</strong>
    <br>Poderemos recolher, tratar, utilizar e guardar os seus dados, com as seguintes finalidades:
    <ul>
      <li>Gestão de encomendas e transações comerciais.</li>
      <li>Gestão de reclamações e questões relacionadas com os nossos produtos e serviços.</li>
      <li>Análise para efeitos de estudos de mercado e criação de perfis de consumidores.</li>
      <li>Obtenção de informações relevantes para definição e implementação de atividades de marketing (comunicações de marketing directo, envio de materiais ou campanhas, comunicações sobre atualização de informação/conteudos ou meios de interação consigo).</li>
    </ul>

    <strong>Armazenamento e Conservação da informação</strong>
    <br>Conservaremos os seus dados durante o período de tempo necessário à realização das atividades acima indicadas ou durante um período de 5 (cinco) anos. Antes do termino deste período poderemos contactá-lo para solicitar a autorização para prolongar esse período.
    Relativamente a dados pessoais de pessoas coletivas,  reservamo-nos o direito de os armazenar por período indeterminado.
    Estes direitos de conservação dos dados podem ser revogados caso indique que assim o pretende, tal como descrito na secção "Os seus Direitos".

    <br><br><strong>Os seus Direitos</strong>
    <br>O direito de acesso aos seus dados pessoais – sempre que o solicitar; Direito de retificar ou completar os dados cedidos; Eliminar as suas informações da nossa base de dados; Limitação ao acesso para algumas das atividades anteriormente descritas; O direito de apresentar queixa à entidade competente;
    <br>Se decidir exercer algum destes direitos, poderá fazer contacta-nos através dos seguintes meios: <span (click)="emailTo()" class="emailTo">farmacia.huguette.ribeiro&#64;gmail.com</span> ou para a morada: Farmácia Huguette Ribeiro, Rua Mar e Guerra, N.º 31, 8005-511 Patacão, Faro

    <br><br>Comprometemo-nos a respeitar a sua decisão informada sobre a recolha e utilização dos seus dados pessoais!
  </small>
</div>
<div class="text-right copyright">
  &copy; {{ today | date:'yyyy' }}. <span (click)="isPrivacyVisible = !isPrivacyVisible;" class="terms-conditions-link">Política de Privacidade</span>.
</div>
<br>